import React, { useState, useEffect } from "react";
import { Alert, Modal, Button, Row, Col, Container, Form} from 'react-bootstrap';
import axios from 'axios';
import { FaSearch, FaArrowLeft } from "react-icons/fa";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const MasterProposalDetail = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [isPdf, setIsPdf] = useState(false);

    const navigate = useNavigate();
    const { id_proposal, usernya } = useParams();
    const username = usernya;
    
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');


    const handleExport = () => {
        setLoading(true);
        // Membuat worksheet dari data
        const worksheetData = [];
        
        if(data.type==2){
            worksheetData.push(
                ['Informasi Perusahaan'],  // Menambahkan baris judul pertama
                ['Nama Perusahaan', data.nama_perusahaan],
                ['Bentuk Usaha', data.bentuk_perusahaan],
                ['Kategory Mitra', data.kategori_mitra],
                ['Alamat Kantor (Sesuai NPWP)', data.alamat_kantor],
                ['Alamat Korespondensi', data.alamat_korespondensi],
                ['Nomor Telepon Kantor', data.nomor_telepon],
                ['Nomor Faksimili Kantor', data.nomor_fax],
                ['Email Kantor', data.email_kantor],
                ['Struktur Organisasi', data.struktur_organisasi ? 'File' : '-'],
                ['Logo Perusahaan', data.logo_perusahaan ? 'File' : '-'],
                [''],  // Menambahkan baris judul pertama
                ['Keanggotaan Perusahaan'],  // Menambahkan baris judul pertama
                ['Nama Anggota Komisaris Utama', data.komisaris_utama],
                ['Nama Anggota Komisaris 1', data.komisaris_1],
                ['Nama Anggota Komisaris 2', data.komisaris_2],
                ['Nama Direktur Utama', data.direktur_utama],
                ['Nama Direktur 1', data.direktur_1],
                ['Nama Direktur 2', data.direktur_2],
                ['Pemegang Saham 1 ', data.pemegang_saham_1],
                ['Pemegang Saham 2', data.pemegang_saham_2],
                ['Pemegang Saham 3', data.pemegang_saham_3],
                ['Nama PIC', data.nama_pic],
                ['Jabatan PIC', data.jabatan_pic],
                ['NO HP PIC', data.hp_pic],
                [''],  // Menambahkan baris judul pertama
                ['Informasi Direktur Utama'], 
                ['Email Direktur Utama', data.email_dirut],
                ['No. Hp Direktur Utama', data.nomor_telepon_dirut],
                ['KTP Direktur Utama', data.ktp_dirut],
                ['NPWP Direktur Utama', data.npwp_dirut],
                [''],  // Menambahkan baris judul pertama
                ['Informasi File Perusahaan'], 
                ['NPWP Perusahaan', data.npwp_perusahaan],
                ['SPPKP', data.sppkp],
                ['NIB', data.nib],
                ['Surat Haki', data.surat_haki],
                ['Kepesertaan BPJS Ketenagakerjaan', data.bpjs_ketenagakerjaan],
                ['Akta Pendirian', data.akta_pendirian],
                ['Pengesahan Akta Pendirian', data.pengesahan_akta_pendirian],
                ['AD/ART', data.ad_art],
                ['Fotokopi KTP Perwakilan', data.fotokopi_ktp],
                [''],  // Menambahkan baris judul pertama
                ['Aspek Legalitas'], 
                ['Dokumen Validasi', data.file_dokumen_validasi ? 'File' : '-'],
                ['Dokumen Kerjasama', data.file_dokumen_legalitas ? 'File' : '-'],
                [''],  // Menambahkan baris judul pertama
                ['Laporan Keuangan Holding'],
                ['2023', data.laporan_keuangan_holding_2023 ? 'File' : '-'],
                ['2022', data.laporan_keuangan_holding_2022 ? 'File' : '-'],
                ['2021', data.laporan_keuangan_holding_2021 ? 'File' : '-'],
                ['Laporan Laba Rugi'],
                ['2023', data.laporan_laba_rugi_2023 ? 'File' : '-'],
                ['2022', data.laporan_laba_rugi_2022 ? 'File' : '-'],
                ['2021', data.laporan_laba_rugi_2021 ? 'File' : '-'],
                ['Laporan SPT'],
                ['2023', data.laporan_spt_2023 ? 'File' : '-'],
                ['2022', data.laporan_spt_2022 ? 'File' : '-'],
                ['2021', data.laporan_spt_2021 ? 'File' : '-'],
                ['Laporan Neraca'],
                ['2023', data.laporan_neraca_2023 ? 'File' : '-'],
                ['2022', data.laporan_neraca_2022 ? 'File' : '-'],
                ['2021', data.laporan_neraca_2021 ? 'File' : '-']
                [''],
                ['Informasi Dokumen Pengajuan'],
                ['Dokumen Pengajuan', data.file_proposal ? 'File' : '-'],
                ['Dokumen Kerjasama', data.file_dokumen_kerjasama ? 'File' : '-'],
              )
        }else if(data.type==3){
            worksheetData.push(
                ['Informasi Perusahaan'],  // Menambahkan baris judul pertama
                ['Nama Perusahaan', data.nama_perusahaan],
                ['Bentuk Usaha', data.bentuk_perusahaan],
                ['Kategory Mitra', data.kategori_mitra],
                ['Alamat Kantor (Sesuai NPWP)', data.alamat_kantor],
                ['Alamat Korespondensi', data.alamat_korespondensi],
                ['Nomor Telepon Kantor', data.nomor_telepon],
                ['Nomor Faksimili Kantor', data.nomor_fax],
                ['Email Kantor', data.email_kantor],
                ['Struktur Organisasi', data.struktur_organisasi ? 'File' : '-'],
                ['Logo Perusahaan', data.logo_perusahaan ? 'File' : '-'],
                [''],  // Menambahkan baris judul pertama
                ['Keanggotaan Perusahaan'],  // Menambahkan baris judul pertama
                ['Nama Anggota Komisaris Utama', data.komisaris_utama],
                ['Nama Anggota Komisaris 1', data.komisaris_1],
                ['Nama Anggota Komisaris 2', data.komisaris_2],
                ['Nama Direktur Utama', data.direktur_utama],
                ['Nama Direktur 1', data.direktur_1],
                ['Nama Direktur 2', data.direktur_2],
                ['Pemegang Saham 1 ', data.pemegang_saham_1],
                ['Pemegang Saham 2', data.pemegang_saham_2],
                ['Pemegang Saham 3', data.pemegang_saham_3],
                ['Nama PIC', data.nama_pic],
                ['Jabatan PIC', data.jabatan_pic],
                ['NO HP PIC', data.hp_pic],
                [''],  // Menambahkan baris judul pertama
                ['Informasi Direktur Utama'], 
                ['Email Direktur Utama', data.email_dirut],
                ['No. Hp Direktur Utama', data.nomor_telepon_dirut],
                ['KTP Direktur Utama', data.ktp_dirut],
                ['NPWP Direktur Utama', data.npwp_dirut],
                [''],  // Menambahkan baris judul pertama
                ['Informasi File Perusahaan'], 
                ['NPWP Perusahaan', data.npwp_perusahaan],
                ['SPPKP', data.sppkp],
                ['NIB', data.nib],
                ['Surat Haki', data.surat_haki],
                ['Kepesertaan BPJS Ketenagakerjaan', data.bpjs_ketenagakerjaan],
                ['Akta Pendirian', data.akta_pendirian],
                ['Pengesahan Akta Pendirian', data.pengesahan_akta_pendirian],
                ['AD/ART', data.ad_art],
                ['Fotokopi KTP Perwakilan', data.fotokopi_ktp],
                [''],  // Menambahkan baris judul pertama
                ['Laporan Keuangan Holding'],
                ['2023', data.laporan_keuangan_holding_2023 ? 'File' : '-'],
                ['2022', data.laporan_keuangan_holding_2022 ? 'File' : '-'],
                ['2021', data.laporan_keuangan_holding_2021 ? 'File' : '-'],
                ['Laporan Laba Rugi'],
                ['2023', data.laporan_laba_rugi_2023 ? 'File' : '-'],
                ['2022', data.laporan_laba_rugi_2022 ? 'File' : '-'],
                ['2021', data.laporan_laba_rugi_2021 ? 'File' : '-'],
                ['Laporan SPT'],
                ['2023', data.laporan_spt_2023 ? 'File' : '-'],
                ['2022', data.laporan_spt_2022 ? 'File' : '-'],
                ['2021', data.laporan_spt_2021 ? 'File' : '-'],
                ['Laporan Neraca'],
                ['2023', data.laporan_neraca_2023 ? 'File' : '-'],
                ['2022', data.laporan_neraca_2022 ? 'File' : '-'],
                ['2021', data.laporan_neraca_2021 ? 'File' : '-'],
                [''],
                ['Quality Management'],
                ['PenTest (Security)', data.pentest ? 'File' : '-'],
                ['ISO 27001', data.iso_27001 ? 'File' : '-'],
                ['Tidak ada Transaksi Suspect ', data.no_suspect ? 'File' : '-'],
                ['Success Rate', data.success_rate ? 'File' : '-'],
                ['Sistem Monitoring Transaksi', data.monitoring_transaksi ? 'File' : '-'],
                ['IT Support 24/7', data.it_support ? 'File' : '-'],
                ['Contact Center 24/7', data.contact_center ? 'File' : '-'],
                ['Standarisasi Resi/Struk', data.standard_resi ? 'File' : '-'],
                ['Original SN Tsel pada Struk', data.sn_struk ? 'File' : '-'],
                ['Standard Penamaan SN', data.standard_sn ? 'File' : '-'],
                ['Contact Center Mitra pada Resi/struk', data.contact_center_resi ? 'File' : '-'],
                ['Stress Test ', data.stress_test ? 'File' : '-'],
                [''],
                ['Infrastruktur'],
                ['Koneksi dengan minimum 1 ISP', data.koneksi_isp ? 'File' : '-'],
                ['Topologi Jaringan', data.topologi_jaringan ? 'File' : '-'],
                ['Networking Monitoring Sistem ', data.network_monitoring ? 'File' : '-'],
                ['Disaster Recovery Center', data.disaster_recovery ? 'File' : '-'],
                [''],
                ['Aspek Legalitas'],
                ['Asset Perusahaan', data.asset_perusahaan ? 'File' : '-'],
                ['Ijin Usaha', data.ijin_usaha ? 'File' : '-'],
                ['Dokumen Validasi Standarisasi Kesisteman Mitra Modern', data.file_dok_val ? 'File' : '-'],
                [''],
                ['Informasi Dokumen Pengajuan'],
                ['Dokumen Pengajuan', data.file_proposal ? 'File' : '-'],
                ['Dokumen Kerjasama', data.file_dokumen_kerjasama ? 'File' : '-'],
              )
        }else if(data.type==4){
            worksheetData.push(
                ['Informasi Perusahaan'],  // Menambahkan baris judul pertama
                ['Nama Perusahaan', data.nama_perusahaan],
                ['Bentuk Usaha', data.bentuk_perusahaan],
                ['Kategory Mitra', data.kategori_mitra],
                ['Alamat Kantor (Sesuai NPWP)', data.alamat_kantor],
                ['Alamat Korespondensi', data.alamat_korespondensi],
                ['Nomor Telepon Kantor', data.nomor_telepon],
                ['Nomor Faksimili Kantor', data.nomor_fax],
                ['Email Kantor', data.email_kantor],
                ['Struktur Organisasi', data.struktur_organisasi ? 'File' : '-'],
                ['Logo Perusahaan', data.logo_perusahaan ? 'File' : '-'],
                [''],  // Menambahkan baris judul pertama
                ['Keanggotaan Perusahaan'],  // Menambahkan baris judul pertama
                ['Nama Anggota Komisaris Utama', data.komisaris_utama],
                ['Nama Anggota Komisaris 1', data.komisaris_1],
                ['Nama Anggota Komisaris 2', data.komisaris_2],
                ['Nama Direktur Utama', data.direktur_utama],
                ['Nama Direktur 1', data.direktur_1],
                ['Nama Direktur 2', data.direktur_2],
                ['Pemegang Saham 1 ', data.pemegang_saham_1],
                ['Pemegang Saham 2', data.pemegang_saham_2],
                ['Pemegang Saham 3', data.pemegang_saham_3],
                ['Nama PIC', data.nama_pic],
                ['Jabatan PIC', data.jabatan_pic],
                ['NO HP PIC', data.hp_pic],
                [''],  // Menambahkan baris judul pertama
                ['Informasi Direktur Utama'], 
                ['Email Direktur Utama', data.email_dirut],
                ['No. Hp Direktur Utama', data.nomor_telepon_dirut],
                ['KTP Direktur Utama', data.ktp_dirut],
                ['NPWP Direktur Utama', data.npwp_dirut],
                [''],  // Menambahkan baris judul pertama
                ['Quality Management'],
                ['PenTest (Security)', data.pentest ? 'File' : '-'],
                ['ISO 27001', data.iso_27001 ? 'File' : '-'],
                ['Tidak ada Transaksi Suspect ', data.no_suspect ? 'File' : '-'],
                ['Success Rate', data.success_rate ? 'File' : '-'],
                ['Sistem Monitoring Transaksi', data.monitoring_transaksi ? 'File' : '-'],
                ['IT Support 24/7', data.it_support ? 'File' : '-'],
                ['Contact Center 24/7', data.contact_center ? 'File' : '-'],
                ['Standarisasi Resi/Struk', data.standard_resi ? 'File' : '-'],
                ['Original SN Tsel pada Struk', data.sn_struk ? 'File' : '-'],
                ['Standard Penamaan SN', data.standard_sn ? 'File' : '-'],
                ['Contact Center Mitra pada Resi/struk', data.contact_center_resi ? 'File' : '-'],
                ['Stress Test ', data.stress_test ? 'File' : '-'],
                [''],
                ['Infrastruktur'],
                ['Koneksi dengan minimum 1 ISP', data.koneksi_isp ? 'File' : '-'],
                ['Topologi Jaringan', data.topologi_jaringan ? 'File' : '-'],
                ['Networking Monitoring Sistem ', data.network_monitoring ? 'File' : '-'],
                ['Disaster Recovery Center', data.disaster_recovery ? 'File' : '-'],
                [''],
                ['Aspek Legalitas'],
                ['Asset Perusahaan', data.asset_perusahaan ? 'File' : '-'],
                ['Ijin Usaha', data.ijin_usaha ? 'File' : '-'],
                ['Dokumen Validasi Standarisasi Kesisteman Mitra Modern', data.file_dok_val ? 'File' : '-'],
                [''],
                ['Informasi Dokumen Pengajuan'],
                ['Dokumen Pengajuan', data.file_proposal ? 'File' : '-'],
              )
        }
        
      
          // Membuat worksheet dari array data
          const ws = XLSX.utils.aoa_to_sheet(worksheetData);
          
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Data Perusahaan');
      
          // Menggunakan tipe binary untuk menulis file Excel
          const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });
      
          // Mendownload file dengan file-saver
          const excelBlob = new Blob([new Uint8Array(atob(excelFile).split("").map(char => char.charCodeAt(0)))], { type: 'application/octet-stream' });
      
          // Menggunakan file-saver untuk menyimpan file Excel
          saveAs(excelBlob, data.nama_perusahaan+'.xlsx');
          setLoading(false);
        };
    

  const handleDownload = async () => {
    setLoading(true);
    const zip = new JSZip();

    try {
        // Daftar file yang ingin Anda unduh dan tambahkan ke ZIP
        const filesToDownload = [];
        const requiredFields = [];

        if(data.type==2){
            requiredFields.push('logo_perusahaan','struktur_organisasi','file_ktp_dirut','file_npwp_dirut','file_npwp_perusahaan',
                'file_sppkp','file_nib','file_bpjs_ketenagakerjaan','file_akta_pendirian','file_pengesahan_akta_pendirian','file_ad_art',
                'file_surat_haki','file_fotokopi_ktp','file_dokumen_legalitas','file_dokumen_validasi','laporan_keuangan_holding_2023',
                'laporan_keuangan_holding_2022','laporan_keuangan_holding_2021','laporan_laba_rugi_2023','laporan_laba_rugi_2022','laporan_laba_rugi_2021',
                'laporan_spt_2023','laporan_spt_2022','laporan_spt_2021','laporan_neraca_2023','laporan_neraca_2022','laporan_neraca_2021','file_proposal','file_dokumen_kerjasama') ;
        }else if(data.type==3){
            requiredFields.push('logo_perusahaan', 'struktur_organisasi', 'file_ktp_dirut', 'file_npwp_dirut', 'file_npwp_perusahaan', 'file_sppkp', 'file_nib', 'file_bpjs_ketenagakerjaan',
            'file_akta_pendirian', 'file_pengesahan_akta_pendirian', 'file_ad_art', 'file_surat_haki', 'file_fotokopi_ktp', 'laporan_keuangan_holding_2023', 'laporan_keuangan_holding_2022', 
            'laporan_keuangan_holding_2021', 'laporan_laba_rugi_2023', 'laporan_laba_rugi_2022', 'laporan_laba_rugi_2021', 'laporan_spt_2023', 'laporan_spt_2022', 'laporan_spt_2021', 'laporan_neraca_2023', 
            'laporan_neraca_2022', 'laporan_neraca_2021', 'ijin_usaha', 'asset_perusahaan', 'file_dok_val', 'koneksi_isp', 'topologi_jaringan', 'network_monitoring', 'disaster_recovery', 
            'pentest', 'no_suspect', 'iso_27001', 'success_rate', 'monitoring_transaksi', 'it_support', 'contact_center', 'standard_resi', 'sn_struk', 'standard_sn', 'contact_center_resi', 'stress_test', 'file_proposal', 'file_dokumen_kerjasama')
        }else if(data.type==4){
            requiredFields.push('logo_perusahaan', 'struktur_organisasi', 'file_ktp_dirut', 'file_npwp_dirut', 'ijin_usaha', 'asset_perusahaan', 
                'file_dok_val', 'koneksi_isp', 'topologi_jaringan', 'network_monitoring', 'disaster_recovery', 'pentest', 'no_suspect',
                'iso_27001', 'success_rate', 'monitoring_transaksi', 'it_support', 'contact_center', 'standard_resi', 'sn_struk', 'standard_sn', 'contact_center_resi', 'stress_test', 'file_proposal')
        }

        /*for (const field of requiredFields) {
            if(data[field]){
                if(field == "file_ktp_dirut" || field == "file_npwp_dirut"){
                    filesToDownload.push({ name: data.nama_perusahaan+'_'+field+'.jpg', url: process.env.REACT_APP_SERVER + '/uploads/data_dirut/'+data[field] })    
                }else if()
                filesToDownload.push({ name: data.nama_perusahaan+'_'+field+'.zip', url: process.env.REACT_APP_SERVER + '/uploads/data_dirut/'+data[field] })
            }
        }*/

        
        for (const field of requiredFields) {
            if(data[field]){
                if(field=='file_ktp_dirut' || field=='file_npwp_dirut'){
                    filesToDownload.push({ name: data.nama_perusahaan+'_'+field+'.jpg', url: process.env.REACT_APP_SERVER + '/uploads/data_dirut/'+data[field] })    
                }else if(field=='ijin_usaha' || field=='asset_perusahaan' || field=='file_dok_val'){
                    filesToDownload.push({ name: data.nama_perusahaan+'_'+field+'.pdf', url: process.env.REACT_APP_SERVER + '/uploads/dokumen_switching_3/'+data[field] })    
                }else if(field=='file_proposal' || field=='file_dokumen_kerjasama'){
                    filesToDownload.push({ name: data.nama_perusahaan+'_'+field+'.zip', url: process.env.REACT_APP_SERVER + '/uploads/dokumen/'+data[field] })        
                }else if(field=='pentest' || field=='no_suspect' || field=='iso_27001' || field=='success_rate' || field=='monitoring_transaksi' || field=='it_support' 
                    || field=='contact_center' || field=='standard_resi' || field=='sn_struk' || field=='standard_sn' || field=='contact_center_resi' || field=='stress_test'){
                    filesToDownload.push({name: data.nama_perusahaan+'_'+field+'.pdf', url: process.env.REACT_APP_SERVER + '/uploads/dokumen_switching_2/'+data[field] })        
                }else if(field=='koneksi_isp' || field=='topologi_jaringan' || field=='network_monitoring' || field=='disaster_recovery'){
                    filesToDownload.push({name: data.nama_perusahaan+'_'+field+'.pdf', url: process.env.REACT_APP_SERVER + '/uploads/dokumen_switching_1/'+data[field] })        
                }else if(field=='file_npwp_perusahaan' || field=='file_sppkp' || field=='file_nib' || field=='file_bpjs_ketenagakerjaan' || field=='file_akta_pendirian' || field=='file_pengesahan_akta_pendirian'
                    || field=='file_ad_art' || field=='file_surat_haki' || field=='file_fotokopi_ktp' || field=='file_dokumen_legalitas' || field=='file_dokumen_validasi'){
                        filesToDownload.push({name: data.nama_perusahaan+'_'+field+'.pdf', url: process.env.REACT_APP_SERVER + '/uploads/file_perusahaan/'+data[field] })        
                }else if( field=='laporan_keuangan_holding_2023' || field=='laporan_keuangan_holding_2022' || field=='laporan_keuangan_holding_2021' || field=='laporan_laba_rugi_2023' || field=='laporan_laba_rugi_2022' ||
                    field=='laporan_laba_rugi_2021' || field=='laporan_spt_2023' || field=='laporan_spt_2022' || field=='laporan_spt_2021' || field=='laporan_neraca_2023' || field=='laporan_neraca_2022' || field=='laporan_neraca_2021'){
                        filesToDownload.push({name: data.nama_perusahaan+'_'+field+'.pdf', url: process.env.REACT_APP_SERVER + '/uploads/laporan_keuangan/'+data[field] })        
                }else if(field=='logo_perusahaan'){
                    filesToDownload.push({name: data.nama_perusahaan+'_'+field+'.png', url: process.env.REACT_APP_SERVER + '/uploads/logo/'+data[field] })        
                }else if(field=='struktur_organisasi'){
                    filesToDownload.push({name: data.nama_perusahaan+'_'+field+'.pdf', url: process.env.REACT_APP_SERVER + '/uploads/logo/'+data[field] })        
                }
            }
        }

        // Loop untuk mengunduh dan menambahkan setiap file ke ZIP
        for (let file of filesToDownload) {
        const response = await fetch(file.url);

        if (!response.ok) {
            setLoading(false);
            throw new Error(`Failed to fetch ${file.name}`);
        }

        // Mengambil file sebagai Blob
        const fileBlob = await response.blob();

        // Verifikasi ukuran file yang diunduh
        console.log(`${file.name} size:`, fileBlob.size);
        if (fileBlob.size === 0) {
            setLoading(false);
            throw new Error(`${file.name} is empty.`);
        }

        // Menambahkan file ke dalam ZIP dengan nama yang sesuai
        zip.file(file.name, fileBlob);
        }

        // Membuat file ZIP dan mendownloadnya
        const zipContent = await zip.generateAsync({ type: 'blob' });

        // Verifikasi ukuran file ZIP
        //console.log('ZIP file size:', zipContent.size);

        // Menyimpan file ZIP
        saveAs(zipContent, data.nama_perusahaan+'.zip'); // Simpan file ZIP
        setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Error during file download or ZIP creation:', error);
        }
    };

     

    const handleShowModal = (contentUrl, title, isPdfFile = false) => {
        setModalContent(contentUrl);
        setModalTitle(title);
        setIsPdf(isPdfFile);
        setShowModal(true);
    };

    
    const handleCloseModal = () => setShowModal(false);


    const handleClickBack = () => {
        navigate(`/master-proposal`);
    };


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/FormReview`, {
                    params: { username }
                });
                setData(response.data);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('An error occurred while fetching data');

            }
        };

        fetchData();
    }, [username]);

    const isFormValid = () => {
        return status && reason; 
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isFormValid()) {
            return;
        }

        Swal.fire({
            title: `Apakah anda telah melakukan review dokumen dan akan memberikan response?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${process.env.REACT_APP_SERVER}/proposalUpdate`, { id_proposal, status, reason })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil!',
                                text: `${response.data.message}`,
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Gagal!',
                                text: `${response.data.message}`,
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                            });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Gagal!',
                            text: 'Terjadi kesalahan, coba lagi.',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                        });
                    });
            }
        });
    };



    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
                   <span onClick={() => handleClickBack()} className="mb-3 bg-dark-subtle mb-4 p-2 rounded"><FaArrowLeft /> <b>Back</b></span>
                   <Alert key="info" variant="info" className="mt-4">
                       Periksa dokumen pengajuan berikut dan berikan respon pada form di bawah
                   </Alert>

           <div style={{ fontSize: '12px', height: "70vh", overflowY: "auto" }}>
               
               <p className="mb-2">
                   <b>Informasi Perusahaan</b>
               </p>                
                     <Col  className="text-end">
                        <Button onClick={handleDownload} className="btn btn-danger me-2" style={{ fontSize: '15px' }}>
                            Download File
                        </Button>
                        <Button onClick={handleExport} className="btn btn-primary me-2" style={{ fontSize: '15px' }}>
                          Download Excel
                        </Button>
                    </Col>
               
               <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Nama Perusahaan <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: <span key="1">{data.nama_perusahaan}</span></Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Bentuk Usaha <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: {data.bentuk_perusahaan}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Kategory Mitra <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: {data.kategori_mitra}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Alamat Kantor (Sesuai NPWP) <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: {data.alamat_kantor}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Alamat Korespondensi <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: {data.alamat_korespondensi}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Nomor Telepon Kantor <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: {data.nomor_telepon}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Nomor Faksimili Kantor</Col>
                       <Col xs={8} className="px-0">: {data.nomor_fax}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Email Kantor <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: {data.email_kantor}</Col>
                   </Row>
                   <Row>
                       <Col xs={4} className="px-0"> Struktur Organisasi</Col>
                       <Col xs={8} className="px-0">: &nbsp;
                           {data.struktur_organisasi ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `logo/${data.struktur_organisasi}`,
                                           'Struktur Organisasi',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0">Logo Perusahaan <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={8} className="px-0">: &nbsp;
                           <span onClick={() => handleShowModal(`logo/${data.logo_perusahaan}`, 'Logo Perusahaan')} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   
               </Container>

               <p className="mb-2">
                   <b>Keanggotaan Perusahaan</b>
               </p>
               <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Anggota Komisaris Utama <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.komisaris_utama}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Anggota Komisaris 1 </Col>
                        <Col xs={8} className="px-0">: {data.komisaris_1}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Nama Anggota Komisaris 2</Col>
                        <Col xs={8} className="px-0">: {data.komisaris_2}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0">: {data.direktur_utama}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Direktur 1</Col>
                        <Col xs={8} className="px-0">: {data.direktur_1}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Direktur 2</Col>
                        <Col xs={8} className="px-0">: {data.direktur_2}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Pemegang Saham 1 <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0">: {data.pemegang_saham_1}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Pemegang Saham 2</Col>
                        <Col xs={8} className="px-0">: {data.pemegang_saham_2}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Pemegang Saham 3</Col>
                        <Col xs={8} className="px-0">: {data.pemegang_saham_3}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama PIC <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0">: {data.nama_pic}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Jabatan PIC <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.jabatan_pic}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">NO HP PIC <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.hp_pic}</Col>
                    </Row>
                </Container>


               <p className="mb-2">
                   <b>Informasi Direktur Utama</b>
               </p>
               <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> Email Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                       <Col xs={8} className="px-0"> : {data.email_dirut}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> No. Hp Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                       <Col xs={8} className="px-0"> : {data.nomor_telepon_dirut}</Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> KTP Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                       <Col xs={5} className="px-0"> : {data.ktp_dirut}</Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`data_dirut/${data.file_ktp_dirut}`, 'KTP Direktur Utama')} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> NPWP Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                       <Col xs={5} className="px-0"> : {data.npwp_dirut} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`data_dirut/${data.file_npwp_dirut}`, 'NPWP Direktur Utama')} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
               </Container>

               {data.type==1 || data.type==2 || data.type==3 ?  
               <><p className="mb-2">
                   <b>Informasi File Perusahaan</b>
               </p>
               <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> NPWP Perusahaan <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0"> : {data.npwp_perusahaan} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_npwp_perusahaan}`, 'NPWP PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> SPPKP <span className="text-danger"><b>*</b> </span> </Col>
                       <Col xs={5} className="px-0"> : {data.sppkp} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_sppkp}`, 'SPPKP PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> NIB <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0">: {data.nib} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_nib}`, 'NIB PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> SURAT HAKI <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0"> : {data.surat_haki} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_surat_haki}`, 'SURAT HAKI', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   {data.type==1 ?  
                       <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> SIUP <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0"> : {data.siup} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_siup}`, 'SIUP PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row> :''}
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> Kepesertaan BPJS Ketenagakerjaan <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0"> : {data.bpjs_ketenagakerjaan}</Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_bpjs_ketenagakerjaan}`, 'KEPESERTAAN BPJS KETENAGAKERJAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> Akta Pendirian Terbaru <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0"> : {data.akta_pendirian} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_akta_pendirian}`, 'AKTA PENDIRIAN TERBARU', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> Pengesahan Akta Pendirian <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0"> : {data.pengesahan_akta_pendirian} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_pengesahan_akta_pendirian}`, 'PENGESAHAN AKTA PENDIRIAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> AD/ART Terbaru <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={5} className="px-0"> : {data.ad_art} </Col>
                       <Col xs={3} className="px-2 text-end">
                           <span onClick={() => handleShowModal(`file_perusahaan/${data.file_ad_art}`, 'AD/ART TERBARU', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                               <FaSearch /> Preview
                           </span>
                       </Col>
                   </Row>
                   {data.type!=1 ?  
                        <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Fotokopi KTP Perwakilan Perusahaan<span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.fotokopi_ktp} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_fotokopi_ktp}`, 'FOTOKOPI KTP PERWAKILAN PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row> :''}
                    {data.type==1 ?  
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Pengesahan AD/ART <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.pengesahan_ad_art} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_pengesahan_ad_art}`, 'PENGESAHAAN AD/ART', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>:''}
               </Container>
              
               {data.type==2 ? 
                <><p className="mb-2">
                                <b>Aspek Legalitas</b>
                            </p><Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                                    <Row className="pb-0 mb-0">
                                        <Col xs={4} className="px-0"> Dokumen Validasi Standarisasi Kesisteman Mitra Modern </Col>
                                        <Col xs={5} className="px-0"> :&nbsp;
                                            {data.file_dokumen_validasi ? (
                                                <span
                                                    onClick={() => handleShowModal(
                                                        `file_perusahaan/${data.file_dokumen_validasi}`,
                                                        'Dokumen Validasi Standarisasi Kesisteman Mitra Modern',
                                                        true
                                                    )}
                                                    className="fw-bold"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    File
                                                </span>
                                            ) : (<>-</>)}
                                        </Col>
                                        <Col xs={4} className="px-0"> Dokumen Legalitas/Kerjasama, Jika Menunjuk Pihak Ketiga <span className="text-danger"><b>*</b> </span></Col>
                                        <Col xs={5} className="px-0"> :&nbsp;
                                        {data.file_dokumen_legalitas ? (
                                            <span
                                                onClick={() => handleShowModal(
                                                    `file_perusahaan/${data.file_dokumen_legalitas}`,
                                                    'Dokumen Legalitas/Kerjasama, Jika Menunjuk Pihak Ketiga',
                                                    true
                                                )}
                                                className="fw-bold"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                File 
                                            </span>
                                        ) : (<>-</>)}
                                        </Col>
                                    </Row>
                                </Container></> : ''}

               <p className="mb-2">
                   <b>Informasi Data Laporan Perusahaan 3 Tahun Terakhir</b>
               </p>
               <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                   <Row className="pb-0 mb-0">
                       <Col xs={6} className="px-0"> Laporan Keuangan Holding </Col>
                       <Col xs={6} className="px-0"> Laporan Rugi Laba </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={3} className="px-0"> 2023 <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_keuangan_holding_2023 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_keuangan_holding_2023}`,
                                           'LAPORAN KEUANGAN HOLDING 2023',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                       <Col xs={3} className="px-0"> 2023 <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_laba_rugi_2023 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_laba_rugi_2023}`,
                                           'LAPORAN LABA RUGI 2023',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File 
                               </span>
                           ) : (<>-</>)}
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={3} className="px-0"> 2022 </Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_keuangan_holding_2022 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_keuangan_holding_2022}`,
                                           'LAPORAN KEUANGAN HOLDING 2022',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                       <Col xs={3} className="px-0"> 2022</Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_laba_rugi_2022 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_laba_rugi_2022}`,
                                           'LAPORAN LABA RUGI 2022',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={3} className="px-0"> 2021 </Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_keuangan_holding_2021 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_keuangan_holding_2021}`,
                                           'LAPORAN KEUANGAN HOLDING 2021',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                       <Col xs={3} className="px-0"> 2021</Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_laba_rugi_2021 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_laba_rugi_2021}`,
                                           'LAPORAN LABA RUGI 2021',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                   </Row>
                   
                   <Row className="pb-0 mb-0 mt-2">
                       <Col xs={6} className="px-0"> Laporan SPT </Col>
                       <Col xs={6} className="px-0"> Laporan Neraca </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={3} className="px-0"> 2023 <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_spt_2023 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_spt_2023}`,
                                           'LAPORAN SPT 2023',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File 
                               </span>
                           ) : (<>-</>)}
                       </Col>
                       <Col xs={3} className="px-0"> 2023 <span className="text-danger"><b>*</b> </span></Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_neraca_2023 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_neraca_2023}`,
                                           'LAPORAN NERACA 2023',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File 
                               </span>
                           ) : (<>-</>)}
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={3} className="px-0"> 2022 </Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_spt_2022 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_spt_2022}`,
                                           'LAPORAN SPT 2022',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                       <Col xs={3} className="px-0"> 2022 </Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_neraca_2022 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_neraca_2022}`,
                                           'LAPORAN NERACA 2022',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                   </Row>
                   <Row className="pb-0 mb-0">
                       <Col xs={3} className="px-0"> 2021 </Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_spt_2021 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_spt_2021}`,
                                           'LAPORAN SPT 2021',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                       <Col xs={3} className="px-0"> 2021 </Col>
                       <Col xs={3} className="px-0">:&nbsp; 
                           {data.laporan_neraca_2021 ? (
                               <span
                                   onClick={() =>
                                       handleShowModal(
                                           `laporan_keuangan/${data.laporan_neraca_2021}`,
                                           'LAPORAN NERACA 2021',
                                           true
                                       )
                                   }
                                   className="fw-bold"
                                   style={{ cursor: 'pointer' }}
                               >
                                   File
                               </span>
                           ) : (<>-</>)}
                       </Col>
                   </Row>
               </Container>
               </> : ''}
               
               {data.type==3 || data.type==4 ? (
                <><p className="mb-2">
                       <b>Dokumen Switching</b>
                       </p><Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                           <Row className="pb-0 mb-0">
                               <Col xs={6} className="px-0"><b>Quality Management </b></Col>
                               <Col xs={6} className="px-0"><b> Infrastruktur </b></Col>
                           </Row>

                           <Row className="pb-0 mb-0">
                           <Col xs={3} className="px-0"> PenTest (Security) <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.pentest ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.pentest}`,
                                               'PenTest (Security)',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0"> Koneksi dengan minimum 1 ISP  <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.koneksi_isp ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_1/${data.koneksi_isp}`,
                                               'Koneksi dengan minimum 1 ISP',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File 
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                           </Row>
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0"> ISO 27001 <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.iso_27001 ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.iso_27001}`,
                                               'ISO 27001',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0">Topologi Jaringan <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.topologi_jaringan ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_1/${data.topologi_jaringan}`,
                                               'Topologi Jaringan',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                           </Row>
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0">Tidak ada Transaksi Suspect <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.no_suspect ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.no_suspect}`,
                                               'Tidak ada Transaksi Suspect',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0">Networking Monitoring Sistem <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.network_monitoring ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_1/${data.network_monitoring}`,
                                               'Networking Monitoring Sistem',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                           </Row>
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0">Success Rate <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.success_rate ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.success_rate}`,
                                               'Success Rate',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0"></Col>
                               <Col xs={3} className="px-0">
                               </Col>
                               {/*
                               <Col xs={3} className="px-0"> Support Program Development <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.support_dev ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.support_dev}`,
                                               'Support Program Development',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               */}
                           </Row>
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0">Sistem Monitoring Transaksi <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.monitoring_transaksi ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.monitoring_transaksi}`,
                                               'Sistem Monitoring Transaksi',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0"></Col>
                               <Col xs={3} className="px-0">
                               </Col>
                           </Row>



                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0"> IT Support 24/7 <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.it_support ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.it_support}`,
                                               'it_support',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File 
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0"><b>ASPEK LEGALITAS SWITCHING</b></Col>
                               <Col xs={3} className="px-0">&nbsp;
                               </Col>
                           </Row>
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0"> Contact Center 24/7 <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.contact_center ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.contact_center}`,
                                               'Contact Center 24/7',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0">Asset Perusahaan <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.asset_perusahaan ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_3/${data.asset_perusahaan}`,
                                               'Asset Perusahaan',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                           </Row>
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0"> Standarisasi Resi/Struk <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.standard_resi ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.standard_resi}`,
                                               'Standarisasi Resi/Struk',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0">Ijin Usaha <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.ijin_usaha ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_3/${data.ijin_usaha}`,
                                               'Ijin Usaha',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                           </Row>
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0"> Original SN Tsel pada Struk <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.sn_struk ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.sn_struk}`,
                                               'Original SN Tsel pada Struk',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File 
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               
                               <Col xs={3} className="px-0"> Dokumen Validasi Standarisasi Kesisteman Mitra Modern </Col>
                                <Col xs={3} className="px-0"> :&nbsp;
                                {data.file_dok_val ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_3/${data.file_dok_val}`,
                                               'Dokumen Validasi Standarisasi Kesisteman Mitra Modern',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File 
                                       </span>
                                   ) : (<>-</>)}
                                </Col>
                           </Row>
                           <Row className="pb-0 mb-0">
                           
                               <Col xs={3} className="px-0"> Standard Penamaan SN <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.standard_sn ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.standard_sn}`,
                                               'Standard Penamaan SN',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               {data.type==2 ?
                               <><Col xs={3} className="px-0"> Dokumen Legalitas/Kerjasama, Jika Menunjuk Pihak Ketiga <span className="text-danger"><b>*</b> </span></Col>
                                <Col xs={3} className="px-0"> :&nbsp;
                                {data.file_dok_leg ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_3/${data.file_dok_leg}`,
                                               ' Dokumen Legalitas/Kerjasama, Jika Menunjuk Pihak Ketiga',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File 
                                       </span>
                                   ) : (<>-</>)}
                                </Col></> : '' }
                                {/*<><Col xs={3} className="px-0">Struktur Organisasi <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   data.struk_org ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_3/${data.struk_org}`,
                                               'Struktur Organisasi',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)
                               </Col></>*/}
                           </Row>

                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0"> Contact Center Mitra pada Resi/struk <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.contact_center_resi ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.contact_center_resi}`,
                                               'Contact Center Mitra pada Resi/struk',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                           
                           
                               <Col xs={3} className="px-0"> Disaster Recovery Center <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.disaster_recovery ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_1/${data.disaster_recovery}`,
                                               'Disaster Recovery Center',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                               <Col xs={3} className="px-0"></Col>
                               <Col xs={3} className="px-0">
                               </Col>
                           </Row>                    
                           <Row className="pb-0 mb-0">
                               <Col xs={3} className="px-0"> Stress Test <span className="text-danger"><b>*</b> </span></Col>
                               <Col xs={3} className="px-0">:&nbsp;
                                   {data.stress_test ? (
                                       <span
                                           onClick={() => handleShowModal(
                                               `dokumen_switching_2/${data.stress_test}`,
                                               'Stress Test',
                                               true
                                           )}
                                           className="fw-bold"
                                           style={{ cursor: 'pointer' }}
                                       >
                                           File
                                       </span>
                                   ) : (<>-</>)}
                               </Col>
                           </Row>   
                       </Container></>
                                   ) : ''}            
               
               

               <p className="mb-2">
                   <b>Informasi Dokumen Pengajuan</b>
               </p>
               <Container
                   style={{ borderBottom: "1px solid #CCC" }}
                   className="pb-4 mb-4"
               >
                   <Row className="pb-0 mb-0">
                       <Col xs={4} className="px-0"> Dokumen Pengajuan <span className="text-danger"><b>*</b></span></Col>
                       <Col xs={8} className="px-0"> : &nbsp;
                           <a href={`${process.env.REACT_APP_SERVER}/uploads/dokumen/${data.file_proposal}`} download>
                               {data.file_proposal} - Download file zip
                           </a>
                       </Col>
                       {data.type==2 || data.type==3 ? 
                        <><Col xs={4} className="px-0"> Dokumen Kerjasama atau Surat Penunjukkan Resmi dari Perusahaan yang menaungi Mitra Channel Tersebut<span className="text-danger"><b>*</b></span></Col>
                        <Col xs={8} className="px-0"> : &nbsp;
                            <a href={`${process.env.REACT_APP_SERVER}/uploads/dokumen/${data.file_dokumen_kerjasama}`} download>
                                {data.file_dokumen_kerjasama} - Download file zip
                            </a>
                        </Col></> : ''}
                   </Row>
                  
                  {data.status_proposal ? 
                   <Form onSubmit={handleSubmit}>
                        <Row className="mb-0 mt-4" style={{border: '0px solid red'}}>
                            <Col xs={4} className="p-2 text-center">
                                <Form.Group controlId="formStatus">
                                    <Form.Control
                                        as="select"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Status</option>
                                        <option value="APPROVED">APPROVED</option>
                                        <option value="REJECTED">REJECTED</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={4} className="p-2 text-end">
                                <Form.Group controlId="formReason" className="mt-0">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter reason"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4} className="p-2 text-start">
                                <Button
                                    className="bg-warning"
                                    style={{ border: "0px solid black" }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>  : ''}
               </Container>
               {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}
               <br /><br /><br /><br /><br/><br/><br/><br/><br/><br/><br/>
           </div>

           {/* Modal for displaying the image or PDF */}
           <Modal
               show={showModal}
               onHide={handleCloseModal}
               dialogClassName={isPdf ? 'modal-fullscreen' : ''}
           >
               <Modal.Header closeButton>
                   <Modal.Title>{modalTitle}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   {isPdf ? (
                       <iframe
                           src={`${process.env.REACT_APP_SERVER}/uploads/${modalContent}`}
                           title={modalTitle}
                           style={{ width: '100%', height: '80vh' }}
                           frameBorder="0"
                       />
                   ) : (
                       <img src={`${process.env.REACT_APP_SERVER}/uploads/${modalContent}`} alt={modalTitle} style={{ width: '100%', height: 'auto' }} />
                   )}
               </Modal.Body>
               <Modal.Footer>
                   <Button variant="secondary" onClick={handleCloseModal}>
                       Close
                   </Button>
               </Modal.Footer>
           </Modal>
       </div>
   );
};

export default MasterProposalDetail;