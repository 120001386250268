import React from 'react'

const MasterHome = () => {
  return (
    <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF' }}>
            <header className="mb-4">
                <h5 className="text-danger">Homepage Administrator</h5>
            </header>
    </div>
  )
}

export default MasterHome
