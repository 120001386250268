import React, { useState, useEffect } from "react";
import { Alert, Modal, Button, Row, Col, Container, Form } from 'react-bootstrap';
import axios from 'axios';
import { FaSearch, FaArrowLeft } from "react-icons/fa";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const MasterPKSDetail = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [isPdf, setIsPdf] = useState(false);

    const navigate = useNavigate();
    const { pks_id, nama_perusahaan } = useParams();

    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');

    const handleShowModal = (contentUrl, title, isPdfFile = false) => {
        setModalContent(contentUrl);
        setModalTitle(title);
        setIsPdf(isPdfFile);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);


    const handleClickBack = () => {
        navigate(`/master-proposal`);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/FormReviewPKS`, {
                    params: { pks_id }
                });
                setData(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('An error occurred while fetching data');
                setLoading(false);
            }
        };

        fetchData();
    }, [pks_id]);

    const isFormValid = () => {
        return status && reason; 
    };


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
            <span onClick={() => handleClickBack()} className="mb-3 bg-dark-subtle mb-4 p-2 rounded"><FaArrowLeft /> <b>Back</b></span>
            <Alert key="info" variant="info" className="mt-4">
                Periksa dokumen pengajuan berikut dan berikan respon pada form di bawah
            </Alert>

            <div style={{ fontSize: '12px', height: "70vh", overflowY: "auto" }}>

                <p className="mb-2">
                    <b>Data PKS</b>
                </p>                
                
                <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">NAMA PERUSAHAAN <span className="text-danger"></span></Col>
                        <Col xs={8} className="px-0">: <span key="1">{data.nama_perusahaan}</span></Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">NOMOR PKS <span className="text-danger"></span></Col>
                        <Col xs={8} className="px-0">: <span key="1">{data.no_pks}</span></Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">LAMPIRAN 1 <span className="text-danger"></span></Col>
                        <Col xs={8} className="px-0">: {data.lampiran_1}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">LAMPIRAN 2 <span className="text-danger"></span></Col>
                        <Col xs={8} className="px-0">: {data.lampiran_2}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">LAMPIRAN 3 <span className="text-danger"></span></Col>
                        <Col xs={8} className="px-0">: {data.lampiran_3}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">LAMPIRAN 4 <span className="text-danger"> </span></Col>
                        <Col xs={8} className="px-0">: {data.lampiran_4}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">LAMPIRAN 4 REDUCE <span className="text-danger"></span></Col>
                        <Col xs={8} className="px-0">: {data.lampiran_4_reduce}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">LAMPIRAN 4 EXPAND</Col>
                        <Col xs={8} className="px-0">: {data.lampiran_4_expand}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={3} className="px-0">LAMPIRAN 5 (BERITA ACARA/KOMITMEN) <span className="text-danger"> </span></Col>
                        <Col xs={8} className="px-0">: {data.lampiran_5}</Col>
                    </Row>
                </Container>
                <br /><br /><br /><br /><br/><br/><br/><br/><br/><br/><br/>
            </div>

            {/* Modal for displaying the image or PDF */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName={isPdf ? 'modal-fullscreen' : ''}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isPdf ? (
                        <iframe
                            src={`${process.env.REACT_APP_SERVER}/uploads/${modalContent}`}
                            title={modalTitle}
                            style={{ width: '100%', height: '80vh' }}
                            frameBorder="0"
                        />
                    ) : (
                        <img src={`${process.env.REACT_APP_SERVER}/uploads/${modalContent}`} alt={modalTitle} style={{ width: '100%', height: 'auto' }} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MasterPKSDetail;