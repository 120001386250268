import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const MAX_FILE_SIZE_MB = 50; // Maksimal ukuran file dalam MB

const FormDokumenPengajuan = ({ uid, tipe, username }) => {
    const [formData, setFormData] = useState({
        dokumen: null,
        dokumen_kerjasama: null
    });

    const [IsFileRequired, setIsFileRequired] = useState(true); 
    const [loading, setLoading] = useState(false);
    const [disabledForm, setdisabledForm] = useState(false); 

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getDokumenProposal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            setdisabledForm(true); // DISABLE WEB
            if(data.length > 0){
                setIsFileRequired(false);
               /* if(data[0].status=='PENDING' || data[0].status=='APPROVED'){
                        setdisabledForm(true); // Menjadikan Logo tidak wajib jika sudah ada
                }*/
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, files } = e.target;
 
         if ((name === 'dokumen' || name === 'dokumen_kerjasama') && files.length > 0) {
            const file = files[0];
             if (!['application/zip', 'application/x-zip-compressed', 'multipart/x-zip', 'application/x-compressed'].includes(file.type)) {
                 Swal.fire({
                     icon: 'warning',
                     title: 'Peringatan',
                     text: 'Hanya file .zip yang diperbolehkan!',
                 });
                 return;
             }
             if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                 Swal.fire({
                     icon: 'warning',
                     title: 'Peringatan',
                     text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                 });
                 return;
             }
 
             setFormData((prevData) => ({
                 ...prevData,
                 [name]: file,
             }));
         }
     };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);


        const requiredFiles = [
            'dokumen',
        ];

        if(tipe==2 || tipe==3){
            requiredFiles.push('dokumen_kerjasama');
        }


        for (const fileName of requiredFiles) {
            if (!formData[fileName] && IsFileRequired==true) {
                Swal.fire({
                    icon: 'warning',
                    title: 'File tidak lengkap',
                    text: `File ${fileName} harus diunggah!`,
                });
                setLoading(false);
                return;
            }
        }

        const usernamePart = `${uid}_${username.split('@')[0]}`;
        const data = new FormData();
        data.append('username', username);

        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                const renamedFile = new File([formData[key]], `${usernamePart}_${key}.zip`, {
                    type: formData[key].type,
                });
                data.append(key, renamedFile);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/uploadDokumen`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil',
                    text: 'Data perusahaan berhasil disimpan',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
            });
        }
        setLoading(false);
    };

    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <p className="mb-4"><b>Dokumen Proposal</b></p>
            <Alert key="info" variant="info">File yang di upload harus berbentuk <b>.zip</b> dengan max file size 50MB.</Alert>
            
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '0px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-0 mb-0'>
                        <Col xs={12} className="px-0">
                            <span style={{ fontSize: '12px' }}>UPLOAD DOKUMEN <span className="text-danger"><b>*</b></span></span>
                        </Col>
                        <Col xs={12} className="px-0">
                            <Form.Control size="sm" type="file" name="dokumen" onChange={handleChange} style={{ width: '100%' }} required={IsFileRequired}  disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max : 50MB | ZIP</p>
                        </Col>
                    </Row>
                    {tipe==2 || tipe==3 ?
                    <><Row className='pb-0 mb-0'>
                            <Col xs={12} className="px-0">
                                <span style={{ fontSize: '12px' }}>UPLOAD DOKUMEN KERJASAMA ATAU SURAT PENUNJUKKAN RESMI DARI PERUSAHAN YANG MENAUNGI MITRA CHANNEL TERSEBUT{tipe==2 ?<span className="text-danger"><b>*</b></span>: <></>}</span>
                            </Col>
                            <Col xs={12} className="px-0">
                                <Form.Control size="sm" type="file" name="dokumen_kerjasama" onChange={handleChange} style={{ width: '100%' }} required={IsFileRequired} disabled={disabledForm} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max : 50MB | ZIP</p>
                            </Col>
                        </Row></>: ''}
                        <Row className='pb-2 mb-0'>
                                <Col xs={12} className="pt-0 px-0 text-end">
                                    <Button type="submit" className="bg-danger text-white" style={{ border: '0px solid black' }}>Simpan</Button>
                                </Col>
                            </Row>
                </Container>
                {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}
            </Form>
        </div>
    );
};

export default FormDokumenPengajuan;