import React, { useState }  from 'react';
import Carousel from 'react-bootstrap/Carousel';

import img1 from './banner/banner1.png';
import img2 from './banner/banner2.png';
import img3 from './banner/banner3.png';
import img4 from './banner/banner4.png';

const BannerSlide = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
                <img
                    className="d-block w-100"
                    src={img4}
                    height={450}
                    alt="The End of Trilogy, Last Sprint eNd-GPP 1"
                    style={{ borderRadius: '15px' }}
                />  
    );
}

export default BannerSlide;
