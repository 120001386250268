import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

import BannerSlide from './BannerSlide';
import homeIkon1 from './images/book.png';
import homeIkon2 from './images/proposal.png';

const Homepage = ( {username, tipe} ) => {
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (username) {
      axios.get(`${process.env.REACT_APP_SERVER}/ProposalStatus`, {
        params: { username }
      })
      .then(response => {
        // Mengasumsikan response.data adalah array dan kita ambil status dari objek pertama
        if (response.data.length > 0) {
          setStatus(response.data[0].status || ''); // Atur status, default ke string kosong jika tidak ada
          setReason(response.data[0].reason || '')
        } else {
          setStatus(''); // Tidak ada data, atur status ke string kosong
        }
      })
      .catch(error => {
        console.error('There was an error fetching the status!', error);
      });
    }
  }, [username]);

  const renderStatusMessage = () => {
    switch (status) {
      case 'APPROVED':
        return <span className="btn btn-success">DISETUJUI</span>;
      case 'REJECTED':
        return <span className="btn btn-danger">DITOLAK. Karena {reason}</span>;
      case 'PENDING':
        return <span className="btn btn-warning">SEDANG DI REVIEW</span>;
      case '':
      case null:
        return <span className="btn btn-info">Anda belum melakukan submit proposal</span>;
      default:
        return <span className="btn btn-danger">Status Proposal tidak diketahui</span>;
    }
  };

  return (
    <div className="p-4">
      <BannerSlide /> {/* Tambahkan kelas "mb-4" untuk margin bottom */}
      <main className="content p-0 mt-4">
        <div className="container-fluid p-0" style={{ border: '0px solid black' }}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">Manual Book</h5>
                </div>
                <div className="card-body">
                  <Container>
                    <Row>
                      <Col xs={4}>
                        <img src={homeIkon1} alt="Manual Book" width={100} height={80} />
                      </Col>
                      <Col xs={8} className="pb-0">
                        <p className="card-text">Download Manual Book & Template Rencana Bisnis untuk membantu proses NGPP Anda.</p>
                        {tipe==1 ? 
                        <a href={process.env.PUBLIC_URL + '/data/manual_book.pdf'} download className="btn btn-danger mx-2">Manual Book</a> : ''}
                        {tipe==2 ? 
                        <a href={process.env.PUBLIC_URL + '/data/manual_book_aggregator.pdf'} download className="btn btn-danger mx-2">Manual Book</a> : ''}
                        {tipe==3 ? 
                        <a href={process.env.PUBLIC_URL + '/data/manual_book_aggregator_switching.pdf'} download className="btn btn-danger mx-2">Manual Book</a> : ''}
                        {tipe==4 ? 
                        <a href={process.env.PUBLIC_URL + '/data/manual_book_switching.pdf'} download className="btn btn-danger mx-2">Manual Book</a> : ''}
                        <a href={process.env.PUBLIC_URL + '/data/ngpp_guidance.pdf'} download className="btn btn-danger">Rencana Bisnis</a>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">Status Proposal</h5>
                </div>
                <div className="card-body">
                  <Container>
                    <Row>
                      <Col xs={4}>
                        <img src={homeIkon2} alt="Template Proposal" width={100} height={80} />
                      </Col>
                      <Col xs={8} className="pb-0">
                          <p className="card-text">Saat ini status proposal submission anda adalah</p>
                          {renderStatusMessage()}
                        { /*<a href={process.env.PUBLIC_URL + '/data/template_proposal.pdf'} download className="btn btn-danger">Download</a> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Homepage;
