import React, { useEffect, useState  } from 'react';
import axios from 'axios';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { Row, Col, Container, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/customTableStyles.css';

const MasterTrackerProposal = () => {
    const [users, setUsers] = useState([]);
    const [tipeMitra, setTipeMitra] = useState(2); // State untuk tipe_mitra

    const fetchData = (tipe) => {
        axios.get(`${process.env.REACT_APP_SERVER}/tracker-proposal`, {
            params: { tipe }
        })
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    };

    useEffect(() => {
        fetchData(tipeMitra); // Ambil data berdasarkan tipe_mitra
    }, [tipeMitra]); // Dependensi

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);

    const columns = React.useMemo(() => {
        const columnsList = [
            { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'USERNAME', accessor: 'username', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'NAMA PERUSAHAAN', accessor: 'nama_perusahaan', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'INFORMASI', accessor: 'informasi_perusahaan', Cell: ({ cell: { value } }) => {
                let bgColor1 = '';
                if (value === 'NOT_YET') bgColor1 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor1 = 'bg-success';
                return <div className={`text-center ${bgColor1}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'KEANGGOTAAN', accessor: 'keanggotaan_perusahaan', Cell: ({ cell: { value } }) => {
                let bgColor2 = '';
                if (value === 'NOT_YET') bgColor2 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor2 = 'bg-success';
                return <div className={`text-center ${bgColor2}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'FILE PERUSAHAAN', accessor: 'file_perusahaan', Cell: ({ cell: { value } }) => {
                let bgColor3 = '';
                if (value === 'NOT_YET') bgColor3 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor3 = 'bg-success';
                return <div className={`text-center ${bgColor3}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'LAP. KEUANGAN', accessor: 'laporan_keuangan', Cell: ({ cell: { value } }) => {
                let bgColor4 = '';
                if (value === 'NOT_YET') bgColor4 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor4 = 'bg-success';
                return <div className={`text-center ${bgColor4}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'ASPEK LEGALITAS', accessor: 'aspek_legalitas', Cell: ({ cell: { value } }) => {
                let bgColor4 = '';
                if (value === 'NOT_YET') bgColor4 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor4 = 'bg-success';
                return <div className={`text-center ${bgColor4}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'INFRASTRUKTUR', accessor: 'infrastruktur', Cell: ({ cell: { value } }) => {
                let bgColor4 = '';
                if (value === 'NOT_YET') bgColor4 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor4 = 'bg-success';
                return <div className={`text-center ${bgColor4}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'QUALITY MANAGEMENT', accessor: 'quality_management', Cell: ({ cell: { value } }) => {
                let bgColor4 = '';
                if (value === 'NOT_YET') bgColor4 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor4 = 'bg-success';
                return <div className={`text-center ${bgColor4}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'PROPOSAL DOC', accessor: 'dokumen_proposal', Cell: ({ cell: { value } }) => {
                let bgColor5 = '';
                if (value === 'NOT_YET') bgColor5 = 'bg-warning';
                else if (value === 'COMPLETED') bgColor5 = 'bg-success';
                return <div className={`text-center ${bgColor5}`} style={{ padding: '0.5rem', color: '#fff' }}>{value}</div>;
            }},
            { Header: 'STATUS', accessor: 'status_proposal', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'REASON', accessor: 'reason', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
        ];
    
        // Kondisi untuk menyembunyikan kolom STATUS jika tipeMitra == 4
        if (tipeMitra === 4) {
            // Menghapus kolom STATUS dari array columnsList
            return columnsList.filter(column => column.accessor !== 'file_perusahaan' && column.accessor !== 'laporan_keuangan');
        } else if (tipeMitra === 2) {
            return columnsList.filter(column => column.accessor !== 'aspek_legalitas' && column.accessor !== 'quality_management'&& column.accessor !== 'infrastruktur');
        }
    
        return columnsList;
    }, [tipeMitra]);  // Pastikan tipeMitra adalah dependency untuk memicu pembaruan ketika nilai tipeMitra berubah
    

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize },
        setPageSize,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,        
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChange = (e) => {
        const { value } = e.target;
        setTipeMitra(Number(value)); // Perbarui tipe_mitra
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
             <header className="mb-4">
                <h5 className="text-danger">Tracker Proposal</h5>
            </header>
            <Container>      
                <Row className='pb-2'>
                    <Col xs={9} className="p-0">  </Col>
                    <Col xs={3} className="p-0">
                            <Form.Select size="sm" name="tipe_mitra" onChange={handleChange} required style={{ width: '96%', display: 'inline-block' }}>
                                <option value="2">AGGREGATOR</option>
                                <option value="3">AGGREGATOR & SWITCHING</option>
                                <option value="4">SWITCHING</option>    
                                <option value="1">TRADITIONAL</option>
                            </Form.Select>
                        </Col>
                </Row>             
                <Row>
                    <Col>
                        <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '12px' }}>
                            <thead className="custom-thead text-center">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                            >
                                                {column.render('Header')}
                                                <span className="sort-arrow"></span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} className="p-1">{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>            

        </div>
    );
};

export default MasterTrackerProposal;
